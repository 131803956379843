<template>
    <div class="order">
        这是订单页
    </div>
</template>
<script>
export default{
    name:"OrderView",
    data(){
        return{

        }
    },
    created(){
        console.log("订单",'--------------')
    },
}
</script>