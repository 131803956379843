import myAxios from '@/utils/MyAxios';

const myRequest = {
    get(url, params){
        const config= {
            method: 'get',
            url: url
        }
        if(params) config.params = params;
        return myAxios(config);
    },
    post(url, params){
        const config= {
            method: 'post',
            url: url
        }
        if(params) config.params = params;
        return myAxios(config);
    },
    put(url, params){
        const config= {
            method: 'put',
            url: url
        }
        if(params) config.params = params;
        return myAxios(config);
    },
    delete(url, params){
        const config= {
            method: 'delete',
            url: url
        }
        if(params) config.params = params;
        return myAxios(config);
    }
}

export default myRequest
