<template>
  <router-view/>
</template>

<script>
import {login} from "@/api/app";

export default {
  name: 'App',
  data(){
    return {

    }
  },
  created() {
    this.loginWeb();
  },
  methods:{
    loginWeb(){
      if(window.localStorage.getItem('is_login') === null || window.localStorage.getItem('is_login') === 'false'){
        login().then(res=>{
          if(res.code === 200){
            window.localStorage.setItem('is_login',true)
          }else{
            window.localStorage.setItem('is_login',false)
          }
        })
      }

    }
  }
}
</script>
