import myRequest from '@/utils/MyRequest';

export function shopList() {
    return myRequest.get("get_last_msg_list");
}

export function login() {
    return myRequest.post("login");
}

export function sendText() {
    return myRequest.post("send");
}