import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/pages/Home.vue'
import ChatRoom from '@/pages/chat_room.vue'
import Order from '@/pages/Order.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      auth: true,
      title: '个人中心',
    },
    component: Home,
  },
  {
    path: '/pages/chat_room',
    name: 'chat_room',
    meta: {
      auth: true,
      title: '聊天室',
    },
    component: ChatRoom,
  },
  {
    path: '/pages/order',
    name: 'order',
    meta: {
      auth: true,
      title: '订单管理',
    },
    component: Order,
  }
]

const router = createRouter({
  routes,
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior() {
    return { top: 0 }
  }
})

export default router
