<template>
  <div class="home">
    <div class="app-title ivu-block ivu-text-center">聊天通讯</div>
    <div class="nav">
        <div class="nav-chat nav-box">
          <Button class="btn btn-chat" type="primary" to="/pages/chat_room">聊天室</Button>
        </div>
        <div class="nav-order nav-box">
          <Button class="btn btn-order" type="info"  to="/pages/order">订单管理</Button>
        </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: 'HomeView',
  data(){
    return{
      
    }
  },
  methods:{

  }
}
</script>
<style>
.home{
  margin-top: 50px;
}
.app-title{
  font-size: 32px;
  color:#5cadff;
}
.nav{
  display: flex;
}
.nav-box{
  flex: 1;
  width: 60px;
  height: 60px;
}
.nav-chat{
  flex: 1;
}
.nav-order{
  flex: 1;
}
.btn{
  margin-top: 100px;
  width: 100px;
  height: auto;
}
.btn-chat{
  float: right;
  margin-right: 10%;
}
.btn-order{
  float: left;
  margin-left: 10%;
}
</style>