<template>
  <div class="chat">
    <div class="chat-shop">
      <Collapse simple v-model="panelIndex" class="list-box" v-for="(item,index) in shopList" :key="index">
          <Panel :name="`${index}`">
            {{ item.name }}
            <template #content>
              <div class="box-list">
                <div class="shop-chat" v-for="(val,k) in item.result " :key="k">
                  <div class="chat-list" @click="openChat(val,item.shop_id)">
                    <div class="head-image">
                      <Avatar size="default" :src="val.avatar"/>
                    </div>
                    <div class="chat-record">
                      <div class="name">{{ val.nickname }}</div>
                      <div class="record">{{ val.content }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </Panel>
      </Collapse>
    </div>
    <div class="content">
      <div class="user-name">{{ chatName }}</div>
      <div :style="Drawer?'flex:8':'flex:9.5'" class="interface" ref="scrollContainer">
          <div v-for="(item,key) in messages" :key="key" :class="item.senderID === shop_id?'right':'left'">
            <div class="chat-content">
              <div class="userAatar" v-if="item.senderID !== shop_id">
                <img class="image" :src="item.avatar" :style="!item.avatar?'background-color:#8e8e8e':''" />
              </div>
              <div class="content" v-if="typeof item.content === 'object'" :style="item.senderID === shop_id?'text-align:right':'text-align:left'">
                <block v-if="item.type === 'listing'" v-for="(v,k) in item.content" :key="k">
                    <Image class="content-image" :src="v.thumbnail.url"  preview :preview-list="[v.thumbnail.url]"></Image>
                </block>
                <block v-if="item.type === 'video'">
                  <video controls preload="metadata" style="border-radius: 10px;width: 200px;height: 400px">
                    <source :src="item.content.resizeVideos[0].url" type="video/mp4">
                  </video>
                </block>
                <block v-else>
                  <Image class="content-image" :src="item.content.thumbnail.url" preview :preview-list="[item.content.thumbnail.url]"></Image>
                </block>
              </div>
              <div class="content" v-else style="justify-content: center;" :style="item.senderID === shop_id?'text-align:right':'text-align:left'">
                  <block v-if="item.type === 'sticker'">
                    <Image class="content-image" :src="item.content" preview :preview-list="[item.content]"></Image>
                  </block>
                  <block v-else>
                    <div v-html="item.content"></div>
                  </block>
              </div>
              <div class="userAatar" v-if="item.senderID === shop_id">
                <img class="image" :src="item.avatar" :style="!item.avatar?'background-color:#8e8e8e':''" />
              </div>
            </div>
            <div class="time">2024-10-01 10:01:05</div>
          </div>
      </div>
      <div v-if="Drawer" class="alert-expre" :style="Drawer?'flex:2':'flex:1'">
        <Tag type="dot" checkable color="error" @click="Drawer = false">返回</Tag>
        <div class="image-box">
          <Image class="image" v-for="(item,key) in expression1" :key="key" :src="item.url"
                 @click="sendExpression(item)"/>
        </div>
        <div class="image-box">
          <Image class="image" v-for="(item,key) in expression2" :key="key" :src="item.url"
                 @click="sendExpression(item)"/>
        </div>
      </div>
      <div v-else class="functional-zone">
        <div class="send-expression">
          <Image class="image" :src="require('@/assets/images/send-expression.png')" @click="Drawer = true"/>
        </div>
        <div class="send-file">
          <Upload :before-upload="sendFile" accept="video/*" format="video"
                  action="//jsonplaceholder.typicode.com/posts/">
            <Image class="image" :src="require('@/assets/images/send-video.png')"/>
          </Upload>
        </div>
        <div class="send-image">
          <Upload :before-upload="sendImage" accept="image/png, image/jpeg" format="image/png, image/jpeg"
                  action="//jsonplaceholder.typicode.com/posts/">
            <Image class="image" :src="require('@/assets/images/send-image.png')"/>
          </Upload>
        </div>
      </div>
      <div class="input-submit">
        <input v-model="content" :disabled="sendType === 'sticker' || sendType === 'image' || sendType === 'video' || sendType === 'text'?true:false" class="input" type="text">
        <Button class="submit" type="primary" :loading="sendType === 'sticker' || sendType === 'image' || sendType === 'video' || sendType === 'text'"  @click="submit" @keyup.enter.native="keydown()">
          <span v-if="sendType === 'sticker' || sendType === 'image' || sendType === 'video'">发送中,请稍后...</span>
          <span v-else>发送</span>
        </Button>
      </div>
    </div>
  </div>
</template>
<script>
import {Avatar, Button, Collapse, Drawer, Image, Panel, Upload} from "view-ui-plus";
import { shopList,sendText } from "@/api/app";
import axios from "axios";
export default {
  name: "ChatView",
  components: {Drawer, Avatar, Image, Upload, Panel, Collapse, Button},
  data() {
    return {
      url:"http://218.86.186.184:13308/",
      is_login:false,
      is_open:false,
      panelIndex: [0],
      content: '',
      expreImage: null,
      fileFile: null,
      imageFile: null,
      expression1: [],
      expression2: [],
      shopList: [],
      messages: [],
      Drawer: false,
      base64Data: '',
      recvicer_id: '',
      shop_id: '',
      chatName:'',
      sendType:'',
      emId:'',
    }
  },
  watch: {
    messages: {
      handler() {
        this.$nextTick(() => {
          const scrollContainer = this.$refs.scrollContainer;
          scrollContainer.scrollTop = scrollContainer.scrollHeight;
        });
      },
      deep: true,
    }
  },
  created() {
    this.queryList();
    this.expressionPush();
  },
  methods: {
    /**
     * 获取店铺列表
     */
    queryList() {
      this.$Loading.start();
      shopList().then(res=>{
        if(res.code === 400){
          this.$Message.error('请求失败！');
          return;
        }
        this.shopList = res.message;
        this.$Loading.finish();
      })
    },
    /**
     * 发送表情
     * @param item
     */
    sendExpression(item) {
      if(!this.is_open){
        this.$Message.error("请打开需要对话的用户！");
        return;
      }
      this.Drawer = true;
      this.emId = 'tunjiang-' + item.value;
      this.expreImage = require('@/assets/images/tunjiang-' + item.value + '.png');
      this.submit()
    },
    /**
     * 发送视频
     * @param file
     * @returns {boolean}
     */
    sendFile(file) {
      if(!this.is_open){
        this.$Message.error("请打开需要对话的用户！");
        return;
      }
      this.fileFile = file;
      this.handleFileUpload(file)
      return false;
    },
    /**
     * 发送图片
     * @param file
     * @returns {boolean}
     */
    sendImage(file) {
      if(!this.is_open){
        this.$Message.error("请打开需要对话的用户！");
        return;
      }
      this.imageFile = file;
      this.handleFileUpload(file)
      return false;
    },
    /**
     * 打开历史聊天记录
     * @param item
     */
    openChat(item,shop_id) {

      this.chatName = item.nickname;
      this.shop_id = shop_id;
      this.recvicer_id = item.revicer_id;
      this.is_open = true;
      const _this = this;
      this.$Loading.start();
      axios.request({
        method: 'POST',
        url: _this.url+'get_history_msg',
        headers: {'content-type': 'application/json'},
        data: {
          shop_id: shop_id,
          revicer_id: item.revicer_id
        }
      }).then(function (response) {
        if(response.data.code === 400){
          _this.$Message.error(response.data.message);
          return;
        }
        let data = response.data;
        _this.messages = JSON.parse(data.message.chat_detail);
        _this.$Loading.finish();
      }).catch(function (error) {
        _this.$Loading.error();
        console.error(error);
      });

    },
    /**
     * 表情赋值
     */
    expressionPush() {
      for (let i = 1; i <= 30; i++) {
        if (i <= 9) {
          i = '0' + i;
        }
        if (i <= 15) {
          this.expression1.push({value: i, url: require('@/assets/images/tunjiang-' + i + '.png')});
        } else {
          this.expression2.push({value: i, url: require('@/assets/images/tunjiang-' + i + '.png')});
        }
      }

    },
    /**
     * 图片/文件转base64
     * @param files
     */
    handleFileUpload(files) {
      const reader = new FileReader();
      const _this = this;
      reader.onload = (e) => {
        _this.base64Data = e.target.result;
        setTimeout(() => {
          _this.submit()
        }, 500)
      };
      reader.readAsDataURL(files);
    },
    /**
     * 时间戳转日期
     */
    // 时间戳转换
    formDate(dateForm) {
      if (dateForm === "") { //解决deteForm为空传1970-01-01 00:00:00
        return "";
      } else {
        var dateee = new Date(dateForm).toJSON();
        var date = new Date(+new Date(dateee) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '');
        return date;
      }
    },

    /**
     * 聊天图片点击
     */
    checkImage() {
      console.log(111);
    },
    /**
     * 发送消息
     * @returns {boolean}
     */
    submit() {
      if (this.expreImage) {
        this.sendType = 'sticker';
        this.content = '<img class="content-image" src="' + this.expreImage + '">';
      } else if (this.fileFile) {
        this.sendType = 'video'
        this.content = '<video  style="border-radius: 10px;width: 200px;height: 400px" controls preload="metadata"><source src="' + this.base64Data + '" type="video/mp4"></video>';
      } else if (this.imageFile) {
        this.sendType = 'image'
        this.content = '<img class="content-image" src="' + this.base64Data +'">';
      } else {
        this.sendType = 'text';
        if (!this.content) {
          this.$Message['error']({
            background: true,
            content: '发送内容不能为空!'
          });
          return false;
        }
      }
      this.request();
    },
    /**
     * 回车发送消息
     */
    keydown() {
      console.log('dsds')
      document.onkeydown = (e) => {
        let key = e.event.keyCode;
        if (key === 13) {
          this.submit();
        }
      }
    },
    request(){
      const _this = this;
      let sendContent;
      if(!this.is_open){
        this.$Message.error('请打开需要对话的用户！');
        return;
      }
      if(this.sendType === 'text'){
        sendContent = this.content;
      }else if(this.sendType === 'image' || this.sendType === 'video'){
        // sendContent = this.base64Data.split(',')[1];
        sendContent = this.base64Data;
      }else if(this.sendType === 'sticker'){
        sendContent = this.emId;
      }
      this.$Loading.start();
      axios.request({
        method: 'POST',
        url: _this.url+'send',
        headers: {'content-type': 'application/json'},
        data: {
          shop_id: this.shop_id,
          type: this.sendType,
          content: sendContent,
          recvicer_id: this.recvicer_id,
        }
      }).then(function (response) {
        if(response.data.code === 400){
          _this.$Message.error(response.data.message);
          return;
        }
        _this.$Loading.finish();
        _this.assignment();
      }).catch(function (error) {
        _this.$Loading.error();
        console.error(error);
      });
    },

    assignment(){
      this.messages.push({
        avatar: require('@/assets/images/1x60x60.png'),
        content:this.content,
        sendTime:Date.now(),
        senderID:this.shop_id,
        type:"text",
      })
      this.queryList();
      this.emprty();
    },
    emprty(){
      this.sendType = '';
      this.content = '';
      this.expreImage = null;
      this.fileFile = null;
      this.imageFile = null;
      this.emId = '';
    }
  }
}
</script>
<style>
.chat {
  width: 90%;
  height: 800px;
  border: 1px solid #eee;
  box-shadow: #2d8cf0 1px 1px 10px 1px, #2d8cf0 1px 1px 10px 1px;
  margin: 20px auto;
  display: flex;

  .chat-shop {
    flex: 4;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    scrollbar-width: none;
    border-right: 1px solid #2d8cf0;

    .list-box {
      width: 95%;
      border-bottom: 1px solid #eee;
      display: flex;
      flex-direction: column;
      box-shadow: #c5c8ce 1px 1px 10px 10px;
      margin: 10px auto;

      .shop-name {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #c5c8ce;
        text-align: center;
        align-content: center;
        justify-items: center;
        justify-content: center;
        color: #464c5b;
        font-size: 20px;
        font-weight: bold;
      }

      .box-list {
        width: 100%;
        max-height: 600px;
        overflow-y: scroll;
        scrollbar-width: none;
        padding: 10px;

        .shop-chat {
          display: flex;
          width: 100%;

          .chat-list {
            flex: 1;
            display: flex;
            border-bottom: dashed 1px #ccc;
            padding: 10px;
            width: 100%;

            .head-image {
              display: flex;
              flex: 1;
              justify-content: center;
              align-content: center;
              align-items: center;

              .image {
                width: 40px;
                height: 40px;
                border-radius: 50%;
              }
            }

            .chat-record {
              padding: 10px;
              flex: 11;
              font-size: 16px;
            }
          }
        }

        .shop-chat:hover {
          color: cornflowerblue;
          cursor: pointer;
        }
      }

      .box-list::-webkit-scrollbar {
        display: none; /* 对于 Chrome, Safari 和 Opera 隐藏滚动条 */
      }
    }
  }

  .content {
    flex: 8;
    display: flex;
    flex-direction: column;

    .user-name {
      flex: 0.5;
      font-size: 18px;
      font-weight: bold;
      text-align: center;
    }

    .interface{
      flex: 9.5;
      border-bottom: 1px solid #2d8cf0;
      overflow-y: scroll;
      scrollbar-width: none;
      .left{
        width: 100%;
        min-height: 100px;
        margin-bottom: 10px;
        float: left;
        display: flex;
        flex-direction: column;
        .chat-content{
          flex: 10;
          min-height: 100px;
          display: flex;
          .content{
            flex: 11;
            font-size: 18px;
            font-weight: bold;
            .content-image{
              width: 120px;
              height: 120px;
            }
          }
          .userAatar{
            flex: 1;
            min-height: 100px;
            text-align: left;
            .image{
              width: 70px;
              height: 70px;
              border-radius: 50%;
              margin-left: 20px;
            }
          }
        }
        .time{
          flex: 2;
          font-size: 14px;
          color: #8e8e8e;
          padding: 5px;
        }
      }
      .right{
        width: 100%;
        min-height: 100px;
        margin-bottom: 10px;
        float: right;
        display: flex;
        flex-direction: column;
        .chat-content{
          flex: 10;
          min-height: 100px;
          display: flex;
          .content{
            font-size: 18px;
            font-weight: bold;
            flex: 11;
            .content-image{
              width: 120px;
              height: 120px;
            }
          }
          .userAatar{
            flex: 1;
            min-height: 100px;
            text-align: right;
            .image{
              width: 70px;
              height: 70px;
              border-radius: 50%;
              margin-right: 20px;
            }
          }
        }
        .time{
          flex: 2;
          font-size: 14px;
          color: #8e8e8e;
          padding: 5px;
          text-align: right;
        }
      }
    }

    .interface::-webkit-scrollbar {
      display: none; /* 对于 Chrome, Safari 和 Opera 隐藏滚动条 */
    }

    .functional-zone {
      flex: 1;
      border-bottom: 1px solid #2d8cf0;
      display: flex;
      float: left;

      .send-expression {
        width: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-right: 1px solid #8e8e8e;
      }

      .send-file {
        width: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-right: 1px solid #8e8e8e;
      }

      .send-image {
        width: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-right: 1px solid #8e8e8e;
      }

      .image {
        width: 40px;
        height: 40px;
      }
    }

    .alert-expre {
      border-bottom: 1px solid #2d8cf0;
      display: flex;
      flex-direction: column;

      .image-box {
        display: flex;

        .image {
          flex: 1;
          padding: 10px;
        }
      }
    }

    .input-submit {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;

      .input {
        flex: 11;
        width: 100%;
        height: 80%;
        padding: 10px;
        border: none;
        font-size: 23px;
        font-weight: bold;
      }

      .submit {
        flex: 1;
      }

      .input:hover {
        border: none;
      }
    }
  }
}

input {
  border: none;
  outline: none;
}
#mask{
  width: 100%;
  opacity: 0.6;
  background-color: gainsboro;
  bottom: 0;
  left: 0;
  position: absolute;
  z-index: 998;
}

</style>