import axios from 'axios';

// 定义api的根路径
// const myBaseURL = process.env.VITE_API_URL;
const myBaseURL = 'http://218.86.186.184:13308';

const service = axios.create({
    baseURL: myBaseURL,
    timeout: 5000,
    // headers: {
    //     "Content-Type": 1111,
    // }
    headers: {'content-type': 'application/json'}
})

// 请求拦截器
service.interceptors.request.use(config => {
    return config;
}, error => {
    console.log("MyAxios Request Error ==========");
    return Promise.reject(error);
})

// 响应拦截器
service.interceptors.response.use(res => {
    if (res.data.code !== 1000){
        console.log(res.data.msg)
    }
    return res.data;
}, error => {
    console.log("MyAxios Response Error ==========");
    return Promise.reject(error)
})

export default service

